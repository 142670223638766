import { trim } from 'lodash';
import { useSearchCompetitionsQuery } from '@/services/api';

export const allDisplayModes = [
  {
    id: 'events_book_events',
    label: 'Book & manage events',
  },
  {
    id: 'competitions_subscription',
    label: 'Subscription',
  },
];

export const loadSearchResults = async (searchQuery) => {
  const response = await useSearchCompetitionsQuery({
    first: 20,
    filter: {
      competitionName: {
        includesInsensitive: trim(searchQuery),
      },
      sport: {
        limbo: {
          equalTo: false,
        },
      },
    },
  });
  return response.data?.competitions?.nodes || [];
};
